import PropTypes from 'prop-types';

import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { LazyIconArrowRightCircle } from '@/design-system/atoms/Icons/IconArrowRightCircle/Lazy';
import { Text } from '@/design-system/atoms/Text';
import { Title } from '@/design-system/atoms/Title';

import styles from './ThreeUpLinkBlock.module.scss';

const ThreeUpLinkBlock = ({ title, linkBlock, ...props }) => {
    return (
        <ContentContainer className={styles[`three-up-link-block`]} {...props}>
            <Title
                tag={Title.TAG.H2}
                variant={Title.VARIANT.H3}
                content={title}
                className={styles[`three-up-link-block__title`]}
            />
            <Grid className={styles[`three-up-link-block__grid`]}>
                {linkBlock?.map((link, index) => (
                    <GridColumn
                        key={index}
                        colSpan={{ sm: 4, md: 6, lg: 4 }}
                        className={styles[`three-up-link-block__block`]}
                    >
                        <Button
                            href={link.href}
                            target={link.target}
                            ariaLabel={link.ariaLabel}
                            label={link.label}
                            buttonStyle={Button.STYLE.TEXT_TITLE}
                            iconComponent={LazyIconArrowRightCircle}
                            className={styles[`three-up-link-block__button`]}
                            analytics={{
                                componentName: '3up Link Block',
                                componentTitle: title,
                                interactionType: 'cta',
                            }}
                            alignment="left"
                        />
                        <div className={styles[`three-up-link-block__content`]}>
                            <Text
                                tag={Text.TAG.P}
                                variant={Text.VARIANT.T2}
                                content={link.description}
                            />
                        </div>
                    </GridColumn>
                ))}
            </Grid>
        </ContentContainer>
    );
};

ThreeUpLinkBlock.propTypes = {
    /**
     * Title of the ThreeUpLinkBlock - required
     */
    title: PropTypes.string.isRequired,
    /**
     * Link block content - required
     */
    linkBlock: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string.isRequired,
            target: PropTypes.string,
            ariaLabel: PropTypes.string,
            label: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    ),
};

export default ThreeUpLinkBlock;
